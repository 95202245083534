import axios from '@axios'
const userData = JSON.parse(localStorage.getItem('userData'))

export default {
  namespaced: true,
  state: {
    tasks: []
  },
  getters: {
    getTasks: (state) => (params) => {
      // Get requested calendars as Array
      const { q = '', filter, tag, sortBy: sortByParam = 'latest' } = params
      /* eslint-enable */

      // ------------------------------------------------
      // Get Sort by and Sort Direction
      // ------------------------------------------------
      let sortDesc = true

      const sortBy = (() => {
        if (sortByParam === 'title-asc') {
          sortDesc = false
          return 'title'
        }
        if (sortByParam === 'title-desc') return 'title'
        if (sortByParam === 'assignee') {
          sortDesc = false
          return 'assignee'
        }
        if (sortByParam === 'due-date') {
          sortDesc = false
          return 'dueDate'
        }
        return 'id'
      })()

      // ------------------------------------------------
      // Filtering
      // ------------------------------------------------
      const queryLowered = q.toLowerCase()

      const hasFilter = task => {
        if (filter === 'important') return task.isImportant && !task.isDeleted
        if (filter === 'completed') return task.isCompleted && !task.isDeleted
        if (filter === 'deleted') return task.isDeleted
        return !task.isDeleted
      }
      /* eslint-disable no-confusing-arrow, implicit-arrow-linebreak, arrow-body-style */
      const filteredData = state.tasks.filter(task => {
        return (task.title.toLowerCase().includes(queryLowered) || task.description.replace(/<[^>]*>?/gm, '').toLowerCase().includes(queryLowered) || (task.assignee ? task.assignee.fullName.toLowerCase().includes(queryLowered) : ''.includes(queryLowered))) && hasFilter(task) && (tag ? task.tags.includes(tag) : true)
        }
      )
      /* eslint-enable  */

      // ------------------------------------------------
      // Perform sorting
      // ------------------------------------------------
      const sortTasks = key => (a, b) => {
        let fieldA
        let fieldB

        // If sorting is by dueDate => Convert data to date
        if (key === 'dueDate') {
          fieldA = new Date(a[key])
          fieldB = new Date(b[key])
          // eslint-disable-next-line brace-style
        }

        // If sorting is by assignee => Use `fullName` of assignee
        else if (key === 'assignee') {
          fieldA = a.member ? a.member.member : null
          fieldB = b.member ? b.member.member : null
        } else {
          fieldA = a[key]
          fieldB = b[key]
        }

        let comparison = 0

        if (fieldA === fieldB) {
          comparison = 0
        } else if (fieldA === null) {
          comparison = 1
        } else if (fieldB === null) {
          comparison = -1
        } else if (fieldA > fieldB) {
          comparison = 1
        } else if (fieldA < fieldB) {
          comparison = -1
        }

        return comparison
      }

      // Sort Data
      const sortedData = filteredData.sort(sortTasks(sortBy))
      if (sortDesc) sortedData.reverse()

      return sortedData

    },
  },
  mutations: {
    updateTasks(state, tasks) {
      state.tasks = tasks
    },
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
  },
  actions: {
    fetchTasks({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/tasks/${userData.church}/${userData.fullName}`)
          .then(response => {

            commit('updateTasks', response.data)
            
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    addTask(ctx, taskData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/task/`, { church: userData.church, user: userData.fullName, ...taskData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateTask(ctx, { task }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/task/${userData.church}/${task.id}`, { user: userData.fullName, ...task })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    taskStatus(ctx, { task }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/task/status/${userData.church}/${task.id}`, { user: userData.fullName, ...task })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeTask(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/task/${userData.church}/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    destroyTask(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/task/delete/${userData.church}/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
